import React from "react";
import LayoutSymtom from "./UserPanel/Layout/LayoutSymtom";
export default function PrivacyPolicy() {
  return (
    <LayoutSymtom>
      <div class="container">
        <div class="privacy_text">
          <h3>Welcome to Toothaid's privacy policy.</h3>
          <p>
            Toothaid Limited respects your privacy and is committed to
            protecting your personal data.{" "}
          </p>
          <p>
            This privacy policy tells you how we look after your personal data
            when you visit our website (regardless of where you visit it from)
            and tells you about your privacy rights and how the law protects
            you.
          </p>
          <p>
            If you decide to use our services we may provide you with additional
            , more specific, information about your personal data and how we use
            it.
          </p>
          <p>
            Please also use the Glossary to understand the meaning of some of
            the terms used in this privacy policy.
          </p>
          <h4>1. DEFINITIONS AND INTERPRETATION</h4>
          <h4>Purpose of this privacy policy</h4>
          <p>
            This privacy policy aims to give you information on how Toothaid
            collects and processes your personal data through your use of this
            website, including any data you may provide through this website
            when you [sign up to our newsletter or purchase a product or
            service.
          </p>
          <p>
            [[This website is not intended for children and we do not knowingly
            collect data relating to children]].
          </p>
          <p>
            It is important that you read this privacy policy together with any
            other privacy policy or fair processing policy we may provide on
            specific occasions when we are collecting or processing personal
            data about you so that you are fully aware of how and why we are
            using your data. This privacy policy supplements other notices and
            privacy policies and is not intended to override them.
          </p>
          <h4>Controller</h4>
          <p>
            Toothaid Limited (referred to as "Toothaid", "we", "us" or "our" in
            this policy) is the data controller and is legally responsible for
            your personal data.
          </p>
          <p>
            We have appointed a [data protection officer (DPO) /[ data privacy
            manager] who is responsible for overseeing questions in relation to
            this privacy policy. If you have any questions about this privacy
            policy, including any requests to exercise your legal rights, please
            contact the [DPO or data privacy manager] using the details set out
            below.
          </p>
          <h4>Contact details</h4>
          <p>
            If you have any questions about this privacy policy or our privacy
            practices, please contact our [DPO OR data privacy manager] in the
            following ways:
          </p>
          <p>Full name of legal entity: [DETAILS]</p>
          <p>Email address: [DETAILS]</p>
          <p>[Telephone number: [DETAILS]]</p>
          <p>
            You have the right to make a complaint at any time to the
            Information Commissioner's Office (ICO), the UK regulator for data
            protection issues (www.ico.org.uk). We would, however, appreciate
            the chance to deal with your concerns before you approach the ICO so
            please contact us in the first instance.
          </p>
          <h4>
            Changes to the privacy policy and your duty to inform us of changes
          </h4>
          <p>
            We keep our privacy policy under regular review. [This version was
            created on [ ]. Historic versions can be obtained by contacting us.
          </p>
          <p>
            It is important that the personal data we hold about you is accurate
            and current. Please keep us informed if your personal data changes
            during your relationship with us.
          </p>
          <h4>Third-party links</h4>
          <p>
            This website may include links to third-party websites, plug-ins and
            applications. Clicking on those links or enabling those connections
            may allow third parties to collect or share data about you. We do
            not control these third-party websites and are not responsible for
            their privacy statements. When you leave our website, we encourage
            you to read the privacy policy of every website you visit.
          </p>
          <h4>2. THE DATA WE COLLECT ABOUT YOU</h4>
          <p>
            Personal data, or personal information, means any information about
            an individual from which that person can be identified. It does not
            include data where the identity has been fully removed (anonymous
            data).
          </p>
          <p>
            We may collect, use, store and transfer different kinds of personal
            data about you which we have grouped together as follows:
          </p>
          <ul>
            <li>
              Identity Data includes first name, maiden name, last name,
              username or similar identifier, marital status, title, date of
              birth and gender.
            </li>
            <li>
              Contact Data includes billing address, delivery address, email
              address and telephone numbers.
            </li>
            <li>
              Financial Data includes bank account and payment card details.
            </li>
            <li>
              Transaction Data includes details about payments to and from you
              and other details of products and services you have purchased from
              us.
            </li>
            <li>
              Technical Data includes internet protocol (IP) address, your login
              data, browser type and version, time zone setting and location,
              browser plug-in types and versions, operating system and platform,
              and other technology on the devices you use to access this
              website.
            </li>
            <li>
              Profile Data includes your username and password, purchases or
              orders made by you, your interests, preferences, feedback and
              survey responses.
            </li>
            <li>
              Usage Data includes information about how you use our website,
              products and services.
            </li>
            <li>
              Marketing and Communications Data includes your preferences in
              receiving marketing from us and our third parties and your
              communication preferences.
            </li>
          </ul>
          <p>
            We also collect, use and share Aggregated Data such as statistical
            or demographic data for any purpose. Aggregated Data could be
            derived from your personal data but is not considered personal data
            in law as this data will not directly or indirectly reveal your
            identity. For example, we may aggregate your Usage Data to calculate
            the percentage of users accessing a specific website feature.
            However, if we combine or connect Aggregated Data with your personal
            data so that it can directly or indirectly identify you, we treat
            the combined data as personal data which will be used in accordance
            with this privacy policy.
          </p>
          <p>
            If you choose to become a patient/purchase or dental services we may
            also collect Special Categories of Personal Data about you (this
            includes details about your health, dental health, medicines, race
            or ethnicity, religious or philosophical beliefs, sex life, sexual
            orientation, and genetic and biometric data). We do not collect any
            information about criminal convictions and offences.
          </p>
          <p>
            For Special Category Data (inc Health data) we have additional
            safeguards in place and must, by law, identify one of the conditions
            needed in order to process it lawfully. We will process your Health
            Data only insofar as is necessary in order to provide you with
            dental treatment. This is the condition listed in article 9 (2)(h)
            of the UK General Data Protection Regulation (UK GDPR). Your
            treatment and the handling of your health information will be under
            the responsibility of one of our dental professionals who is under a
            professional duty of professional secrecy (confidentiality). We also
            have specific internal rules about how Health Data are used, stored
            , protected and retained.
          </p>
          <p>If you do not provide personal data</p>
          <p>
            Where we need to collect personal data by law, or under the terms of
            a contract we have with you, and you fail to provide that data when
            requested, we may not be able to perform the contract we have or are
            trying to enter into with you (for example, to provide you with
            treatment or other services). In this case, we may have to cancel a
            product or service you have with us but we will notify you if this
            is the case at the time.
          </p>
          <h4>3. HOW IS YOUR PERSONAL DATA COLLECTED?</h4>
          <p>
            We use different methods to collect data from and about you
            including through:
          </p>
          <p>
            Direct interactions. You may give us your Identity, Contact and
            Financial Data] by filling in forms or by corresponding with us by
            post, phone, email or otherwise. This includes personal data you
            provide when you:
          </p>
          <ul>
            <li>apply for our products or services;</li>
            <li>create an account on our website;</li>
            <li>subscribe to our service or publications;</li>
            <li>request marketing to be sent to you;</li>
            <li>enter a competition, promotion or survey; or</li>
            <li>give us feedback or contact us.</li>
          </ul>
          <p>
            Automated technologies or interactions. As you interact with our
            website, we will automatically collect Technical Data about your
            equipment, browsing actions and patterns. We collect this personal
            data by using cookies, server logs and other similar technologies.
            We may also receive Technical Data about you if you visit other
            websites employing our cookies. Please see our cookie policy [LINK]
            for further details.
          </p>
          <p>
            Third parties or publicly available sources. We will receive
            personal data about you from various third parties [and public
            sources] as set out below:
          </p>
          <p>Technical Data from the following parties:</p>
          <p>analytics providers [such as Google based outside the UK];</p>
          <p>
            advertising networks [such as [NAME] based [inside OR outside] the
            UK]; and
          </p>
          <p>
            search information providers [such as [NAME] based [inside OR
            outside] the UK].
          </p>
          <p>
            Contact, Financial and Transaction Data from providers of technical,
            payment and delivery services [such as [NAME] based [inside OR
            outside] the UK].
          </p>
          <p>
            Identity and Contact Data from data brokers or aggregators [such as
            [NAME] based [inside OR outside] the UK].
          </p>
          <p>
            Identity and Contact Data from publicly available sources [such as
            Companies House and the Electoral Register based inside the UK].
          </p>
          <p>[ANY OTHER WAYS YOU COLLECT PERSONAL DATA].</p>
          <h4>4. HOW WE USE YOUR PERSONAL DATA</h4>
          <p>
            We will only use your personal data when the law allows us to. Most
            commonly, we will use your personal data in the following
            circumstances:
          </p>
          <ul>
            <li>
              Where we need it in order to perform the contract we are about to
              enter into or have entered into with you.
            </li>
            <li>
              Where it is necessary for our legitimate interests (or those of a
              third party) and your interests and fundamental rights do not
              override those interests.
            </li>
            <li>
              Where we need to comply with a legal obligation or need to enforce
              our legal rights.
            </li>
          </ul>
          <p>
            See below to find out more about the types of lawful basis that we
            will rely on to process your personal data.
          </p>
          <p>
            Generally, we do not rely on consent as a legal basis for processing
            your personal data although we will get your consent before sending
            third party direct marketing communications to you via email or text
            message. You have the right to withdraw consent to marketing at any
            time by contacting us.
          </p>
          <p>
            We may also seek your explicit consent to using your Special
            Category Data (eg health records, including dental health).
          </p>
          <h4>Purposes for which we will use your personal data</h4>
          <p>
            We have set out below, in a table format, a description of all the
            ways we plan to use your personal data, and which of the legal bases
            we rely on to do so. We have also identified what our legitimate
            interests are where appropriate.
          </p>
          <p>
            Note that we may process your personal data for more than one lawful
            ground depending on the specific purpose for which we are using your
            data. Please contact us if you need details about the specific legal
            ground we are relying on to process your personal data where more
            than one ground has been set out in the table below.
          </p>
          <table>
            <tr>
              <th>Purpose/Activity</th>
              <th>Type of data</th>
              <th>
                Lawful basis for processing including basis of legitimate
                interest
              </th>
            </tr>
            <tr>
              <td>To register you as a new customer</td>
              <td>(a) Identity (b) Contact</td>
              <td>Performance of a contract with you</td>
            </tr>
            <tr>
              <td>
                To provide you with dental consultations, treatment and
                medication
              </td>
              <td>(a) Special Category (Health etc)</td>
              <td>
                (a) Performance of a contract with you (b) Explicit consent
              </td>
            </tr>
            <tr>
              <td>
                To process and deliver your order including: (a) Manage
                payments, fees and charges (b) Collect and recover money owed to
                us
              </td>
              <td>
                (a) Identity (b) Contact (c) Financial (d) Transaction (e)
                Marketing and Communications
              </td>
              <td>
                (a) Performance of a contract with you (b) Necessary for our
                legitimate interests (to recover debts due to us)
              </td>
            </tr>
            <tr>
              <td>
                To manage our relationship with you which will include: (a)
                Notifying you about changes to our terms or privacy policy (b)
                Asking you to leave a review or take a survey
              </td>
              <td>
                (a) Identity (b) Contact (c) Profile (d) Marketing and
                Communications
              </td>
              <td>
                (a) Performance of a contract with you (b) Necessary to comply
                with a legal obligation (c) Necessary for our legitimate
                interests (to keep our records updated and to study how
                customers use our products/services)
              </td>
            </tr>
            <tr>
              <td>
                To enable you to partake in a prize draw, competition or
                complete a survey
              </td>
              <td>
                (a) Identity (b) Contact (c) Profile (d) Usage (e) Marketing and
                Communications
              </td>
              <td>
                (a) Performance of a contract with you (b) Necessary for our
                legitimate interests (to study how customers use our
                products/services, to develop them and grow our business)
              </td>
            </tr>
            <tr>
              <td>
                To administer and protect our business and this website
                (including troubleshooting, data analysis, testing, system
                maintenance, support, reporting and hosting of data)
              </td>
              <td>(a) Identity (b) Contact (c) Technical</td>
              <td>
                (a) Necessary for our legitimate interests (for running our
                business, provision of administration and IT services, network
                security, to prevent fraud and in the context of a business
                reorganisation or group restructuring exercise) (b) Necessary to
                comply with a legal obligation
              </td>
            </tr>
            <tr>
              <td>
                To deliver relevant website content and advertisements to you
                and measure or understand the effectiveness of the advertising
                we serve to you
              </td>
              <td>
                (a) Identity (b) Contact (c) Profile (d) Usage (e) Marketing and
                Communications (f) Technical
              </td>
              <td>
                Necessary for our legitimate interests (to study how customers
                use our products/services, to develop them, to grow our business
                and to inform our marketing strategy)
              </td>
            </tr>
            <tr>
              <td>
                To use data analytics to improve our website, products/services,
                marketing, customer relationships and experiences
              </td>
              <td>(a) Technical (b) Usage</td>
              <td>
                Necessary for our legitimate interests (to define types of
                customers for our products and services, to keep our website
                updated and relevant, to develop our business and to inform our
                marketing strategy)
              </td>
            </tr>
            <tr>
              <td>
                To make suggestions and recommendations to you about goods or
                services that may be of interest to you
              </td>
              <td>
                (a) Identity (b) Contact (c) Technical (d) Usage (e) Profile
                (f)Non-electronic Marketing and Communications (g) e-marketing
              </td>
              <td>
                Necessary for our legitimate interests (to develop our
                products/services and grow our business). We will only send you
                e-marketing materials (emails/SMS etc) if we have your explicit
                consent.
              </td>
            </tr>
          </table>
          <h4>Marketing</h4>
          <p>
            We strive to provide you with choices regarding certain personal
            data uses, particularly around marketing and advertising.{" "}
          </p>
          <p>
            If you give us your consent to receive e-marketing then each
            e-communication with you will include a simple to use “unsubscribe”
            mechanism which we will action promptly, ceasing such
            e-communications.
          </p>
          <p>
            We will not mix marketing messages with service-related messages,
            that is messages strictly relevant to the services we are providing
            to you.
          </p>
          <h4>Promotional offers from us</h4>
          <p>
            We may use your Identity, Contact, Technical, Usage , Special
            Category and Profile Data to form a view on what we think you may
            want or need, or what may be of interest to you. This is how we
            decide which products, services and offers may be relevant for you
            (we call this marketing).
          </p>
          <p>
            You will receive postal marketing communications from us if you have
            requested information from us or purchased goods or services from us
            and you have not opted out of receiving that marketing.
          </p>
          <h4>Third-party marketing</h4>
          <p>
            We will get your express opt-in consent before we share your
            personal data with any third party for marketing purposes.
          </p>
          <h4>Opting out</h4>
          <p>
            You can ask us or third parties to stop sending you marketing
            messages at any time by following the opt-out links on any marketing
            message sent to you or by contacting us at any time.
          </p>
          <p>
            Where you opt out of receiving these marketing messages, this will
            not apply to personal data provided to us as a result of a
            product/service purchase, warranty registration, product/service
            experience or other transactions.
          </p>
          <h4>Cookies</h4>
          <p>
            [We use cookies on our website fr a variety of purposes. Please see
            our Cookies Notice. You can also set your browser to refuse all or
            some browser cookies, or to alert you when websites set or access
            cookies. If you disable or refuse cookies, please note that some
            parts of this website may become inaccessible or not function
            properly.{" "}
          </p>
          <h4>Change of purpose</h4>
          <p>
            We will only use your personal data for the purposes for which we
            collected it, unless we reasonably consider that we need to use it
            for another reason and that reason is compatible with the original
            purpose. If you wish to get an explanation as to how the processing
            for the new purpose is compatible with the original purpose, please
            contact us.
          </p>
          <p>
            If we need to use your personal data for an unrelated purpose, we
            will notify you and we will explain the legal basis which allows us
            to do so.
          </p>
          <p>
            Please note that we may process your personal data without your
            knowledge or consent, in compliance with the above rules, where this
            is required or permitted by law.
          </p>
          <h4>5. DISCLOSURES OF YOUR PERSONAL DATA</h4>
          <p>
            We may share your personal data (to the extent necessary) with the
            parties set out below for the purposes set out in the table above.
          </p>
          <ul>
            <li>Third Parties who provide parts of our IT infrastructure;</li>
            <li>
              Other Third Party suppliers and service providers we utilise to
              enable us to provide our services
            </li>
            <li>
              Specific third parties such as your existing or past dental
              practitioner; other health professionals involved in your care and
              treatment;
            </li>
            <li>Our legal advisers and accountants;</li>
            <li>
              Third parties to whom we may choose to sell, transfer or merge
              parts of our business or our assets. Alternatively, we may seek to
              acquire other businesses or merge with them. If a change happens
              to our business, then the new owners may use your personal data in
              the same way as set out in this privacy policy.
            </li>
            <li>
              We require all third parties to respect the security of your
              personal data and to treat it in accordance with the law. We do
              not allow our third-party service providers to use your personal
              data for their own purposes and only permit them to process your
              personal data for specified purposes and in accordance with our
              written instructions.
            </li>
          </ul>
          <h4>6. INTERNATIONAL TRANSFERS</h4>
          <p>
            [IF NO TRANSFERS OUT OF UK OCCUR:] [We do not transfer your personal
            data outside the UK).
          </p>
          <p>
            <b>OR</b>
          </p>
          <p>
            [IF TRANSFERS OUT OF UK OCCUR:] We share your personal data within
            the [COMPANY] Group. This will involve transferring your data
            outside the UK].
          </p>
          <p>
            [IF BINDING CORPORATE RULES ARE USED:] [We ensure your personal data
            is protected by requiring all our group companies to follow the same
            rules when processing your personal data. These rules are called
            "binding corporate rules". For further details, see [ ]].
          </p>
          <p>
            [Many of our external third parties are based outside the UK so
            their processing of your personal data will involve a transfer of
            data outside the UK].
          </p>
          <p>
            Whenever we transfer your personal data out of the UK, we ensure a
            similar degree of protection is afforded to it by ensuring at least
            one of the following safeguards is implemented:
          </p>
          <ul>
            <li>
              We will only transfer your personal data to countries that have
              been deemed to provide an adequate level of protection for
              personal data (such as the EU) or where
            </li>
            <li>
              Additional safeguards have been agreed in our contracts with the
              organisation receiving the data. Where we use certain service
              providers, we may use specific contracts approved for use in the
              UK which give personal data the same protection it has in the UK.{" "}
            </li>
          </ul>
          <p>
            Please contact us if you want further information on the specific
            mechanism used by us when transferring your personal data out of the
            UK.
          </p>
          <h4>7. DATA SECURITY</h4>
          <p>
            We have put in place appropriate security measures to prevent your
            personal data from being accidentally lost, used or accessed in an
            unauthorised way, altered or disclosed. In addition, we limit access
            to your personal data to those employees, agents, contractors and
            other third parties who have a business need to know. They will only
            process your personal data on our instructions and they are subject
            to a duty of confidentiality.
          </p>
          <p>
            We have put in place procedures to deal with any suspected personal
            data breach and will notify you and any applicable regulator of a
            breach where we are legally required to do so.
          </p>
          <h4>8. DATA RETENTION</h4>
          <p>
            <b>How long will you use my personal data for?</b>
          </p>
          <p>
            We will only retain your personal data for as long as reasonably
            necessary to fulfil the purposes we collected it for, including for
            the purposes of satisfying any legal, regulatory, tax, accounting or
            reporting requirements. We may retain your personal data for a
            longer period in the event of a complaint or if we reasonably
            believe there is a prospect of litigation in respect to our
            relationship with you.
          </p>
          <p>
            To determine the appropriate retention period for personal data, we
            consider the amount, nature and sensitivity of the personal data,
            the potential risk of harm from unauthorised use or disclosure of
            your personal data, the purposes for which we process your personal
            data and whether we can achieve those purposes through other means,
            and the applicable legal, regulatory, tax, accounting or other
            requirements.
          </p>
          <p>
            Details of retention periods for different aspects of your personal
            data are available in our retention policy which you can request
            from us by contacting us.{" "}
          </p>
          <p>
            By law we have to keep basic information about our customers
            (including Contact, Identity, Financial and Transaction Data) for
            six years after they cease being customers for tax purposes.
          </p>
          <p>
            Health data will be retained for longer in order to ensure
            continuity and safety of the treatment and care given to you now and
            in the future.
          </p>
          <p>
            In some circumstances you can ask us to delete your data. See below
            for further information.
          </p>
          <p>
            In some circumstances we will anonymise your personal data (so that
            it can no longer be associated with you) for research or statistical
            purposes, in which case we may use this information indefinitely
            without further notice to you.
          </p>
          <h4>9. YOUR LEGAL RIGHTS</h4>
          <p>
            You have various legal rights under UK data protection law. Not all
            rights apply in full in all circumstances, but in summary your
            rights are-
          </p>
          <ul>
            <li>To request access to your personal data].</li>
            <li>To request correction of your personal data].</li>
            <li>To request erasure of your personal data].</li>
            <li>To object to processing of your personal data].</li>
            <li>To request restriction of processing your personal data.</li>
            <li>To request transfer of your personal data.</li>
            <li>To withdraw your consent.</li>
          </ul>
          <p>
            If you wish to exercise any of the rights set out above, please
            contact us.{" "}
          </p>
          <h4>No fee usually required</h4>
          <p>
            You will not have to pay a fee to access your personal data (or to
            exercise any of the other rights). However, we may, by law, charge a
            reasonable fee if your request is clearly unfounded, repetitive or
            excessive. Alternatively, we could refuse to comply with your
            request in these circumstances.
          </p>
          <h4>What we may need from you</h4>
          <p>
            We may need to request specific information from you to help us
            confirm your identity and ensure your right to access your personal
            data (or to exercise any of your other rights). This is a security
            measure to ensure that personal data is not disclosed to any person
            who has no right to receive it. We may also contact you to ask you
            for further information in relation to your request to speed up our
            response.
          </p>
          <h4>Time limit to respond</h4>
          <p>
            We try to respond to all legitimate requests within one month.
            Occasionally it could take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated.
          </p>
          <h4>10. GLOSSARY</h4>
          <h4>LAWFUL BASIS</h4>
          <p>
            Legitimate Interest means the interest of our business in conducting
            and managing our business to enable us to give you the best
            service/product and the best and most secure experience. We make
            sure we consider and balance any potential impact on you (both
            positive and negative) and your rights before we process your
            personal data for our legitimate interests. We do not use your
            personal data for activities where our interests are overridden by
            the impact on you (unless we have your consent or are otherwise
            required or permitted to by law). You can obtain further information
            about how we assess our legitimate interests against any potential
            impact on you in respect of specific activities by contacting us.
          </p>
          <p>
            Performance of Contract means processing your data where it is
            necessary for the performance of a contract to which you are a party
            or to take steps at your request before entering into such a
            contract.
          </p>
          <p>
            Comply with a legal obligation means processing your personal data
            where it is necessary for compliance with a legal obligation that we
            are subject to.
          </p>
          <h4>THIRD PARTIES</h4>
          <ul>
            <li>
              Service providers [acting as processors] based [SPECIFIC
              COUNTRIES] who provide [IT and system administration services].
            </li>
            <li>
              Professional advisers acting as processors, controllers or joint
              controllers including lawyers, bankers, auditors and insurers
              based in [SPECIFIC COUNTRIES] who provide [consultancy, banking,
              legal, insurance and accounting services].
            </li>
            <li>
              HM Revenue & Customs, regulators and other authorities based in
              the United Kingdom.
            </li>
            <li>
              Your current or future dental practitioners and other health
              professionals;
            </li>
            <li>The General Dental Council</li>
          </ul>
          <h4>YOUR LEGAL RIGHTS</h4>
          <p>You have the right to:</p>
          <p>
            Request access to your personal data (commonly known as a "data
            subject access request"). This enables you to receive a copy of the
            personal data we hold about you and to check that we are lawfully
            processing it.
          </p>
          <p>
            Request correction of the personal data that we hold about you. This
            enables you to have any incomplete or inaccurate data we hold about
            you corrected, though we may need to verify the accuracy of the new
            data you provide to us.
          </p>
          <p>
            Request erasure of your personal data. This enables you to ask us to
            delete or remove personal data where there is no good reason for us
            continuing to process it. You also have the right to ask us to
            delete or remove your personal data where you have successfully
            exercised your right to object to processing (see below), where we
            may have processed your information unlawfully or where we are
            required to erase your personal data to comply with local law. Note,
            however, that we may not always be able to comply with your request
            of erasure for specific legal reasons which will be notified to you,
            if applicable, at the time of your request.
          </p>
          <p>
            Object to processing of your personal data where we are relying on a
            legitimate interest (or those of a third party) and there is
            something about your particular situation which makes you want to
            object to processing on this ground as you feel it impacts on your
            fundamental rights and freedoms. You also have the right to object
            where we are processing your personal data for direct marketing
            purposes. In some cases, we may demonstrate that we have compelling
            legitimate grounds to process your information which override your
            rights and freedoms.
          </p>
          <p>
            Request restriction of processing of your personal data. This
            enables you to ask us to suspend the processing of your personal
            data in the following scenarios:
          </p>
          <ul>
            <li>If you want us to establish the data's accuracy.</li>
            <li>
              Where our use of the data is unlawful but you do not want us to
              erase it.
            </li>
            <li>
              Where you need us to hold the data even if we no longer require it
              as you need it to establish, exercise or defend legal claims.
            </li>
            <li>
              You have objected to our use of your data but we need to verify
              whether we have overriding legitimate grounds to use it.
            </li>
          </ul>
          <p>
            Request the transfer of your personal data to you or to a third
            party. We will provide to you, or a third party you have chosen,
            your personal data in a structured, commonly used, machine-readable
            format. Note that this right only applies to automated information
            which you initially provided consent for us to use or where we used
            the information to perform a contract with you.
          </p>
          <p>
            Withdraw consent at any time where we are relying on consent to
            process your personal data. However, this will not affect the
            lawfulness of any processing carried out before you withdraw your
            consent. If you withdraw your consent, we may not be able to provide
            certain products or services to you. We will advise you if this is
            the case at the time you withdraw your consent.
          </p>
        </div>
      </div>
    </LayoutSymtom>
  );
}
