import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
const AreaChart = ({ monthArr, dataArr }) => {
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "months",
      categories: monthArr,
    },
  };

  const series = [
    {
      name: "Income",
      data: dataArr,
    },
  ];

  return (
    <div>
      <Chart options={options} series={series} type="area" height={260} />
    </div>
  );
};

export default AreaChart;
