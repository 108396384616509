import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import admin_avatar_header from "../../../assets/images/avatar.svg";
import { deleteBlog, getBlogs } from "../../../Redux/Actions/admin/adminPanel";
import MomentFunc from "../../../utils/MomentDateTime";
import Layout from "../layout/Layout";
import { startstopLoading } from "../../../Redux/Reducers/globalSlice";
import Modal from "react-bootstrap/Modal";

export default function Blog() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(5);
  const [pageCount, setPageCount] = useState();
  const [resultOffset, setResultOffset] = useState(0);
  const dispatch = useDispatch();
  const blogs = useSelector((state) => state.adminPanel.blogs);
  const HOST_NAME = process.env.REACT_APP_HOST_NAME;
  const [delBlog, setDelete] = useState(false);
  const [searchedMembers, setSearchedMembers] = useState("");
  const [viewVal, setViewVal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(startstopLoading(true));
    dispatch(getBlogs()).then((res) => {
      if (res?.payload?.success == true || res?.payload?.success == false) {
        dispatch(startstopLoading(false));
      }
    });
  }, []);

  const handleBlogDelete = () => {
    dispatch(deleteBlog(deleteId));
    setViewVal(false)
  };

  useEffect(() => {
    if (page && searchedMembers) {
      setPageCount(Math.ceil(searchedMembers.length / page));
    }
  }, [searchedMembers, page]);

  useEffect(() => {
    if (blogs) {
      setSearchedMembers(blogs);
    }
  }, [blogs]);

  useEffect(() => {
    const newArr = blogs?.filter((data) =>
      data?.title.concat(" ", data.author).toLowerCase().includes(search)
    );
    setSearchedMembers(newArr);
  }, [search]);

  const handlePageClick = (event) => {
    setResultOffset((event.selected * page) % searchedMembers.length);
  };

  return (
    <Layout>
      <Modal
        show={viewVal}
        onHide={() => setViewVal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delete_admin_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Blog
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-center mb-0">
            Are you sure you want to delete this Blog?
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleBlogDelete}
            type="submit"
            className="btn btn-primary btn-custom btn-lg w-100 submit_btn confirmation_btn"
          >
            Yes
          </button>
          <button
            onClick={() => setViewVal(false)}
            type="submit"
            className="btn btn-primary btn-custom btn-lg w-100 submit_btn confirmation_btn"
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
      <div id="layout-wrapper" className="bg-dash admin-table">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* <!-- start page title --> */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <div>
                      <span className="small_text">Blogs</span>
                      <h4 className="mb-sm-0 font-size-28">Blogs</h4>
                    </div>
                    <div className="page-title-right">
                      {/* <!-- App Search--> */}
                      <form className="app-search d-none d-lg-block ">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="position-relative w-100">
                            <input
                              value={search}
                              onChange={(e) => {
                                setSearch(e.target.value), setResultOffset(0);
                              }}
                              type="text"
                              className="form-control"
                              placeholder="Search"
                            />
                            <span className="bx bx-search"></span>
                          </div>
                          <img
                            src={admin_avatar_header}
                            className="avata_serch"
                            alt=""
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <div className="members_tbl">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">All Blogs</h4>
                          <span>
                            <Link to="/admin/createblog">
                              <button className="btn cre_new">
                                New Blog Post
                              </button>
                            </Link>
                          </span>
                        </div>
                        <div className="table-responsive table-defaut-design  dealer-table-c">
                          <table
                            id="datatable"
                            className="table  vehicles_table  w-100"
                          >
                            <thead>
                              <tr>
                                <th>Date Posted</th>
                                <th>Image</th>
                                <th>Title</th>
                                <th>Author</th>
                                <th>Actions</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody className="td_color trans_table_fix_height">
                              {searchedMembers ? (
                                searchedMembers
                                  ?.slice(resultOffset, resultOffset + page)
                                  ?.map((blog) => {
                                    return (
                                      <tr key={blog?.id}>
                                        <td valign="middle">
                                          {MomentFunc.Date(blog?.createdAt)}
                                        </td>
                                        <td valign="middle">
                                          <img
                                            className="blog-img-td"
                                            src={
                                              HOST_NAME +
                                              "public/uploads/" +
                                              blog?.img
                                            }
                                            alt=""
                                          />
                                        </td>
                                        <td valign="middle">{blog?.title}</td>
                                        <td valign="middle">{blog?.author}</td>
                                        <td
                                          className="action_btn editBtn"
                                          valign="middle"
                                        >
                                          <Link
                                            to={
                                              blog?.id &&
                                              `/admin/blog/editBlog/${blog?.id}`
                                            }
                                          >
                                            <button className="border_btn">
                                              Edit
                                            </button>
                                          </Link>
                                        </td>
                                        <td valign="middle" className="viewBtn">
                                          <Link
                                            to={`/admin/blog/viewBlog/${blog?.id}`}
                                          >
                                            <button className="btn pending_genr ">
                                              View
                                            </button>
                                          </Link>
                                        </td>
                                        <td
                                          valign="middle"
                                          className="delete_button"
                                        >
                                          <button
                                            onClick={
                                              () => {
                                                setDeleteId(blog?.id);
                                                setViewVal(true);
                                              }
                                              // dispatch(deleteBlog(blog.id))
                                            }
                                            className="btn  delete-disable p-0 m-0 delete-red-text"
                                          >
                                            Delete
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })
                              ) : (
                                <tr>
                                  <td
                                    colSpan={5}
                                    valign={"middle"}
                                    className="h-370"
                                  >
                                    <p className="no_content_table">
                                      No Blogs Yet
                                    </p>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center pagination_inner mt-1">
                      <ReactPaginate
                        className="pagination"
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- table row end --> */}
            </div>
            {/* <!-- container fluid --> */}
          </div>
          {/* <!-- End Page-content --> */}
        </div>
        {/* <!-- end main content--> */}
      </div>
    </Layout>
  );
}
