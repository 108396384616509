import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConversation } from "../../../Redux/Actions/chat";
import { addMessage } from "../../../Redux/Reducers/chatSlice";

const ChatBox = ({ userDetails, socket, showChat, onlineUsers }) => {
  const dispatch = useDispatch();
  const conversation = useSelector((state) => state.chat.conversation);
  const [stateForScroll, setStateForScroll] = useState();
  const [text, setText] = useState("");
  const [convoId, setConvoId] = useState("");
  const ref = useChatScroll(stateForScroll);

  useEffect(() => {
    setConvoId(userDetails.conversationId);
  }, [userDetails]);

  useEffect(() => {
    if (convoId) {
      (async () => {
        dispatch(getConversation(convoId));
        await socket.emit("join_room", convoId);
      })();
    }
  }, [convoId]);

  useEffect(() => {
    socket.on("recieve_message", (messageObject) => {
      dispatch(addMessage(messageObject));
      setStateForScroll(messageObject.message);
    });
  }, [socket]);

  function useChatScroll(dep) {
    const ref = useRef();
    useEffect(() => {
      if (ref.current) {
        ref.current.scrollTop = ref.current.scrollHeight;
      }
    }, [dep]);
    return ref;
  }

  const sendText = (event) => {
    event.preventDefault();
    if (text) {
      const messageObject = {
        convoId: convoId,
        message: text,
        sender: "doctor",
        time: JSON.stringify(new Date()),
      };
      socket.emit("send_message", messageObject);
      dispatch(addMessage(messageObject));
      setStateForScroll(text);
      setText("");
    }
  };  
  
  return (
    <div className="col-xl-4">
      <div className="card">
        <div className="card-body">
          <div className="chat-box-c">
            <div className="note-header-part p-0 mb-4 border-0">
              <div className="d-flex flex-column">
                <h4 className="title_text mb-0">{userDetails?.fullName}</h4>
                {onlineUsers?.findIndex(
                  (item) => item.userId == userDetails?.user_id
                ) != -1 ? (
                  <div class="online-status">
                    <span class="dot-green"></span>
                    Online
                  </div>
                ) : null}
              </div>

              <button
                style={{ border: "none" }}
                onClick={() => showChat(false)}
                className="end-chat-btn"
              >
                End Chat
              </button>
            </div>
            <form onSubmit={sendText}>
              <div ref={ref} className="chat-area-height chat-height-fix">
                {Object.keys(conversation).length > 0 &&
                  conversation[convoId]?.map((message, index) => {
                    return message.sender === "user" ? (
                      <div
                        key={index}
                        className="left-side-chat admin-text-left"
                      >
                        <span>{message?.message}</span>
                      </div>
                    ) : (
                      <div
                        key={index}
                        className="right-side-chat admin-text-right"
                      >
                        <span>{message?.message}</span>
                      </div>
                    );
                  })}
              </div>
              <div className="send-msg-part hide_scroll">
                <textarea
                  onChange={(e) => setText(e.target.value)}
                  className="border-0"
                  placeholder="Type here..."
                  value={text}
                ></textarea>
                <button className="btn send-chat-btn" type="submit">
                  <i className="bx bxs-send"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBox;

{
  /* .map((message, index) => {
                      console.log(message); */
}
{
  /* return message.sender === "user" ? (
                        <div key={index} className="left-side-chat">
                          <span>{message?.message}</span>
                        </div>
                      ) : (
                        <div key={index} className="right-side-chat">
                          <span>{message?.message}</span>
                        </div>
                      ); */
}
