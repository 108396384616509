import React, { useEffect } from "react";
import Layout from "../layout/Layout";
import admin_avatar_header from "../../../assets/images/avatar.svg";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewBlog } from "../../../Redux/Actions/admin/adminPanel";
import parse from "html-react-parser";
import draftToHtml from "draftjs-to-html";
import { startstopLoading } from "../../../Redux/Reducers/globalSlice";

const ViewBlog = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const blog = useSelector((state) => state.adminPanel.viewBlog);
  const HOST_NAME = process.env.REACT_APP_HOST_NAME;
  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <div></div>;
      }
    },
  };

  useEffect(() => {
    dispatch(startstopLoading(true));
    dispatch(viewBlog(id)).then((res) => {
      if (res?.payload?.success == true || res?.payload?.success == false) {
        dispatch(startstopLoading(false));
      }
    });
  }, []);

  return (
    <div>
      <Layout>
        <div id="layout-wrapper" className="bg-dash admin-table">
          <div className="main-content">
            <div className="page-content">
              {blog?._id == id && (
                <div className="container-fluid">
                  {/* <!-- start page title --> */}
                  <div className="row">
                    <div className="col-12">
                      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <div>
                          <span className="small_text">Blogs</span>
                          <h4 className="mb-sm-0 font-size-28">Blogs</h4>
                        </div>
                        <div className="page-title-right">
                          {/* <!-- App Search--> */}
                          <form className="app-search d-none d-lg-block ">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="position-relative w-100">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                />
                                <span className="bx bx-search"></span>
                              </div>
                              <img
                                src={admin_avatar_header}
                                className="avata_serch"
                                alt=""
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row blog_detail_page">
                    <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-6 col-sm-6 mb-4">
                      <h2 className=" mb-2">{blog?.title}</h2>
                      <div className="media-body d-flex justify-content-between">
                        <ul className="list-inline d-flex">
                          <li>
                            Posted:{" "}
                            <span className="text-theme-color-2 me-3">
                              {blog?.createdAt}
                            </span>
                          </li>
                          <li>
                            By:{" "}
                            <span className="text-theme-color-2">
                              {blog?.author}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <img
                        src={HOST_NAME.concat("public/uploads/").concat(
                          "",
                          blog?.img
                        )}
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="px-0 pb-0">
                        <div className="media mt-4 mb-0 pt-1">
                          <div className="media-body blog-content-html">
                            {blog?.blogContent &&
                              parse(
                                draftToHtml(JSON.parse(blog?.blogContent)),
                                options
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ViewBlog;
