import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { toast } from "react-toastify";
import  msgIcon from '../assets/images/message_icon.svg'; 

const firebaseConfig = {
  apiKey: "AIzaSyAIxbje3F1EMq3bZVNTk9OvFl3fmMkb7xg",
  authDomain: "toothaid-e4521.firebaseapp.com",
  projectId: "toothaid-e4521",
  storageBucket: "toothaid-e4521.appspot.com",
  messagingSenderId: "402165650980",
  appId: "1:402165650980:web:235bd6e04eee2ef82b6ab1",
  measurementId: "G-MQ10FRXNWT"
};

initializeApp(firebaseConfig);

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: `BL_ghgMkV6WPL3DGQgJ7wERYohNRDTZLR0a4XP47P5hOAf6GvpRmccj-yFZB2PHYgK_k6kg-OR337BhdbIMuaFA`,
  })
    .then((currentToken) => {
      if (currentToken) {
        sessionStorage.setItem('device_token', currentToken);
        console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
     console.log(payload,"payloadpayload");
     
        toast(payload?.notification?.title, {
          icon: <img src={msgIcon} width="20" height="20" />,
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "light",
          style: { color: "black" },
        });
         
      resolve(payload);
    });
  });