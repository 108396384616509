import React from "react";

const Questionnare = ({ medHistory }) => {
  return (
    <div className="card">
      <div className="consulatation_card doctorNotes">
        <div className="upcoming_con_title">
          <h3 className="common_title">Consultation Notes</h3>
        </div>
      </div>
      <div className="consulatation_card notes_height p-3 questionnaire_view">
        {medHistory.map((med) => {
          return (
            <div className="row mb-2">
              <div className="col-md-8">
                <div className="collap-text">{med?.question}</div>
                {med.answer == "Yes" && (
                  <div className="sub-question-show pb-0">
                    <div className="subques">{med.subQues}</div>
                    <div>
                      <div className="select_option_btn  box_shadow w-100  history_input_field ques_view_input_field">
                        {med.inputField}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-4">
                <div className="d-flex justify-content-end">
                  <div
                    className={
                      med.answer == "Yes"
                        ? "select-btn-yes medHisColor"
                        : "select-btn-yes"
                    }
                  >
                    Yes
                  </div>
                  <div
                    className={
                      med.answer == "No"
                        ? "select-btn-yes medHisColor"
                        : "select-btn-yes"
                    }
                  >
                    No
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Questionnare;
