import React from "react";

const MedicalHistory = ({ medHistory }) => {
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="tbl_user_info">
          <div className="card">
            <div className="card-body medical_accordian">
              <div className="d-flex justify-content-between align-items-center flex-wrap  position-relative ">
                <h4 className="title_text mb-4">Medical History</h4>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <div
                    className="accordion adminaccordion "
                    id="accordionExample"
                  >
                    <div className="accordion-item ">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button fw-medium"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Medical History
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show overflow-auto h-200"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body h-100">
                          {medHistory ? (
                            medHistory?.map((med) => {
                              return (
                                <div key={med._id} className="row mb-2">
                                  <div className="col-md-10">
                                    <div className="collap-text">
                                      {med.question}
                                    </div>
                                    {med.answer == "Yes" && (
                                      <div className="sub-question-show">
                                        <div className="subques">
                                          {med.subQues}
                                        </div>
                                        <div>
                                          <div className="select_option_btn  box_shadow w-100  history_input_field">
                                            {med.inputField}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-2">
                                    <div className="d-flex">
                                      <div
                                        className={
                                          med.answer == "Yes"
                                            ? "select-btn-yes medHisColor"
                                            : "select-btn-yes"
                                        }
                                      >
                                        Yes
                                      </div>
                                      <div
                                        className={
                                          med.answer == "No"
                                            ? "select-btn-yes medHisColor"
                                            : "select-btn-yes"
                                        }
                                      >
                                        No
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="no_history_table">
                              No Medical History
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- end accordion --> */}
                </div>
                {/* <!-- end col -->    */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalHistory;
