import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getMemberDetails } from "../../../Redux/Actions/admin/adminPanel";
import Layout from "../layout/Layout";
import ConsultationsPrescriptions from "./ConsultationsPrescriptions";
import CustomerDetails from "./CustomerDetails";
import MedicalHistory from "./MedicalHistory";
import MemberDetailsTitle from "./MemberDetailsTitle";
import { startstopLoading } from "../../../Redux/Reducers/globalSlice";

export default function MemberDetails() {
  const dispatch = useDispatch();
  const memberId = useParams().id;
  const memberDetails = useSelector((state) => state.adminPanel.memberDetails);
  const path = useLocation();

  useEffect(() => {
    dispatch(startstopLoading(true));
    dispatch(getMemberDetails(memberId)).then((res) => {
      if (res?.payload?.success == true || res?.payload?.success == false) {
        dispatch(startstopLoading(false));
      }
    });

    if (path.pathname.includes("cons")) {
      const url = path.pathname.replace("cons", "admin");
      window.location.href = url;
    }
  }, []);

  return (
    <Layout>
      <div id="layout-wrapper" className="bg-dash admin-table">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {memberDetails && (
                <>
                  <MemberDetailsTitle
                    fullName={memberDetails?.fullName}
                    joinedAt={memberDetails?.joinedAt}
                  />
                  <CustomerDetails
                    email={memberDetails?.email}
                    fullName={memberDetails?.fullName}
                    age={memberDetails?.age}
                    address={memberDetails?.address}
                  />
                  <ConsultationsPrescriptions
                    id={memberDetails?._id}
                    email={memberDetails?.email}
                    fullName={memberDetails?.fullName}
                    upcomingConsultations={memberDetails?.upcomingConsultations}
                    pastConsultations={memberDetails?.pastConsultations}
                    prescriptions={memberDetails?.prescriptions}
                  />
                  <MedicalHistory medHistory={memberDetails?.medHistory} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
