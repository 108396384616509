import { useFormik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { createDentist, editDentist } from "../../../Redux/Actions/admin/adminPanel";
import { toast } from "react-toastify";

const DentitstModal = ({ show, handleClose,dentistDetail }) => {

    const dispatch = useDispatch();
    const validate = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = "Required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Invalid email address";
        }
        if (!values.firstName) {
            errors.firstName = "Required";
        }
        if (!values.lastName) {
            errors.lastName = "Required";
        }
        if (!values.phnNumber) {
            errors.phnNumber = "Required";
        }
        return errors;
    };

    const formik = useFormik({
        enableReinitialize:true,
        initialValues: {
            id:dentistDetail?._id || "",
            firstName: dentistDetail?.firstName || "",
            lastName: dentistDetail?.lastName || "",
            email: dentistDetail?.email || "",
            phnNumber: dentistDetail?.phnNumber || "",
        },
        validate,
        onSubmit: async (values) => {
            try {
                if(dentistDetail){
                    dispatch(editDentist(values)).then((data)=>{
                        if(data?.payload?.success){
                            toast.success(data?.payload?.message)
                        }else{
                            toast.error(data?.payload?.message)
                        }
                    });
                }else{
                    dispatch(createDentist(values)).then((data)=>{
                        if(data?.payload?.success){
                            toast.success(data?.payload?.message)
                        }else{
                            toast.error(data?.payload?.message)
                        }
                    });
                }
                formik.resetForm();
                handleClose()
               
            } catch (error) {
                console.log(error);
            }
        },
    });

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Create Dentist
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="popup-input-element">
                            <div className="form-group mb-3">
                                <label htmlFor="">First Name</label>
                                <input
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    placeholder="First Name"
                                    name="firstName"
                                    value={formik.values.firstName}
                                />
                                {formik.errors.firstName ? (
                                    <p className="formik-errors">{formik.errors.firstName}*</p>
                                ) : null}
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="">Surname</label>
                                <input
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    placeholder="Surname"
                                    name="lastName"
                                    value={formik.values.lastName}
                                />
                                {formik.errors.lastName ? (
                                    <p className="formik-errors">{formik.errors.lastName}*</p>
                                ) : null}
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="">Email Address</label>
                                <input
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    placeholder="Email Address"
                                    name="email"
                                    value={formik.values.email}
                                />
                                {formik.errors.email ? (
                                    <p className="formik-errors">{formik.errors.email}*</p>
                                ) : null}
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="">Phone Number</label>
                                <div className="d-flex align-items-center phn-form-control">
                                    <div className="country_code">
                                        <p>+44</p>
                                    </div>
                                    <div className="phn-form-control_width">
                                        <input
                                            type="number"
                                            className="form-control "
                                            onChange={formik.handleChange}
                                            placeholder=""
                                            name="phnNumber"
                                            value={formik.values.phnNumber}
                                        />
                                    </div>

                                </div>

                                {formik.errors.phnNumber ? (
                                    <p className="formik-errors">{formik.errors.phnNumber}*</p>
                                ) : null}
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className="btn_submit ">
                            <button
                                type="submit"
                                className="submit-btn-popup"
                            >
                                Submit
                            </button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default DentitstModal