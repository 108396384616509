import React from "react";
import LayoutSymtom from "./Layout/LayoutSymtom";
export default function DataProcessingPolicy() {
  return (
    <>
      <LayoutSymtom>
        <div class="container">
          <div class="privacy_text">
            <h3>
              TOOTHAID LIMITED <br />
              DATA PROCESSING ADDENDUM (CONTROLLER-TO-PROCESSOR (TRANSFER OF
              PERSONAL DATA OUTSIDE EEA) (UK))
            </h3>

            <h4>1. DEFINITIONS AND INTERPRETATION</h4>
            <p>
              The following definitions and rules of interpretation apply in
              this agreement.
            </p>
            <h5>1.1 Definitions:</h5>
            <ul>
              <h5> Authorised Persons</h5>
              <li>
                the persons or categories of persons that the Customer
                authorises to give the Provider written personal data processing
                instructions as identified in Appendix 1 and from whom the
                Provider agrees to accept such instructions.
              </li>
              <h5> Business Purposes </h5>
              <li>
                the services to be provided by the Provider to the Customer as
                described in the [Master Agreement] and any other purpose
                specifically identified in Appendix 1 .
              </li>
              <h5> Commissioner </h5>
              <li>
                the Information Commissioner (see Article 4(A3), UK GDPR and
                section 114, DPA 2018).
              </li>
              <h5>
                {" "}
                Controller, Processor, Data Subject, Personal Data, Personal
                Data Breach and Processing
              </h5>
              <li>
                {" "}
                have the meanings given to them in the Data Protection
                Legislation.
              </li>
              <h5> Data Protection Legislation</h5>
              <li>
                (a) To the extent the UK GDPR applies, the law of the United
                Kingdom or of a part of the United Kingdom which relates to the
                protection of Personal Data.
              </li>
              <li>
                (b) To the extent the EU GDPR applies, the law of the European
                Union or any member state of the European Union to which the
                Customer or Provider is subject, which relates to the protection
                of Personal Data].
              </li>
              <h5> Data Subject</h5>
              <li>
                {" "}
                the identified or identifiable living individual to whom the
                Personal Data relates.
              </li>
              <h5> EU GDPR</h5>
              <li> the General Data Protection Regulation ((EU) 2016/679)].</li>
              <h5> EEA</h5>
              <li> the European Economic Area.</li>
              <h5> Standard Contractual Clauses (SCCs) </h5>
              <li>
                {" "}
                the ICO's International Data Transfer Agreement for the transfer
                of personal data from the UK and/or the ICO's International Data
                Transfer Addendum to EU Commission Standard Contractual Clauses
                and/or the European Commission's Standard Contractual Clauses
                for the transfer of personal data to third countries pursuant to
                Regulation (EU) 2016/679 as set out in the Annex to Commission
                Implementing Decision (EU) 2021/914 and/or the European
                Commission's Standard Contractual Clauses for the transfer of
                Personal Data from the European Union to processors established
                in third countries (controller-to-processor transfers), as set
                out in the Annex to Commission Decision 2010/87/EU [as adapted
                for the UK], a completed copy of which comprises Appendix 2 [or
                such alternative clauses as may be approved by the European
                Commission or by the UK from time to time].
              </li>
              <h5> Term</h5>
              <li> this agreement's term as defined in Clause 10.</li>
              <h5> UK GDPR</h5>
              <li>
                {" "}
                has the meaning given to it in section 3(10) (as supplemented by
                section 205(4)) of the DPA 2018.
              </li>
            </ul>
            <h5>
              1.2 This Addendum relates to the terms of the [Master Agreement]
              and is incorporated into the Master Agreement. Interpretations and
              defined terms set forth in the Master Agreement apply to the
              interpretation of this agreement.
            </h5>
            <h5>
              1.3 The Annexes form part of this Addendum and will have effect as
              if set out in full in the body of this agreement. Any reference to
              this agreement includes the Annexes.
            </h5>
            <h5>1.4 A reference to writing or written includes email.</h5>
            <h5>1.5 In the case of conflict or ambiguity between:</h5>
            <ul>
              <li>
                any provision contained in the body of this Addendum and any
                provision contained in the Annexes, the provision in the body of
                this Addendum will prevail;
              </li>
              <li>
                the terms of any accompanying invoice or other documents annexed
                to this Addendum and any provision contained in the Annexes, the
                provision contained in the Annexes will prevail;
              </li>
              <li>
                any of the provisions of this Addendum and the provisions of the
                Master Agreement, the provisions of this Addendum will prevail;
                and
              </li>
              <li>
                any of the provisions of this Addendum and any executed SCC, the
                provisions of the executed SCC will prevail
              </li>
            </ul>
            <h4>2. PERSONAL DATA TYPES AND PROCESSING PURPOSES</h4>
            <ul>
              <li>
                The Customer and the Provider agree and acknowledge that for the
                purpose of the Data Protection Legislation:
              </li>
              <li>
                the Customer is the controller and the Provider is the processor
              </li>
              <li>
                the Customer retains control of the Personal Data and remains
                responsible for its compliance obligations under the applicable
                Data Protection Legislation, including but not limited to
                providing any required notices and obtaining any required
                consents, and for the written processing instructions it gives
                to the Provider.
              </li>
              <li>
                Appendix 1 describes the subject matter, duration, nature and
                purpose of the processing and the Personal Data categories and
                Data Subject types in respect of which the Provider may process
                the Personal Data to fulfil the Business Purposes.
              </li>
            </ul>
            <h4>3. PROVIDER'S OBLIGATIONS</h4>
            <ul>
              <li>
                The Provider will only process the Personal Data to the extent,
                and in such a manner, as is necessary for the Business Purposes
                in accordance with the Customer's written instructions from
                Authorised Persons. The Provider will not process the Personal
                Data for any other purpose or in a way that does not comply with
                this agreement or the Data Protection Legislation. The Provider
                must promptly notify the Customer if, in its opinion, the
                Customer's instructions do not comply with the Data Protection
                Legislation.
              </li>
              <li>
                The Provider must comply promptly with any Customer written
                instructions from Authorised Persons]requiring the Provider to
                amend, transfer, delete or otherwise process the Personal Data,
                or to stop, mitigate or remedy any unauthorised processing.
              </li>
              <li>
                The Provider will maintain the confidentiality of the Personal
                Data and will not disclose the Personal Data to third parties
                unless the Customer or this Addendum specifically authorises the
                disclosure, or as required by domestic law, court or regulator
                (including the Commissioner). If a domestic law, court or
                regulator (including the Commissioner) requires the Provider to
                process or disclose the Personal Data to a third party, the
                Provider must first inform the Customer of such legal or
                regulatory requirement and give the Customer an opportunity to
                object or challenge the requirement, unless the domestic law
                prohibits the giving of such notice.
              </li>
              <li>
                The Provider will reasonably assist the Customer, at no
                additional cost to the Customer, with meeting the Customer's
                compliance obligations under the Data Protection Legislation,
                taking into account the nature of the Provider's processing and
                the information available to the Provider, including in relation
                to Data Subject rights, data protection impact assessments and
                reporting to and consulting with the Commissioner or other
                relevant regulator under the Data Protection Legislation.
              </li>
              <li>
                The Provider must promptly notify the Customer of any changes to
                the Data Protection Legislation that may reasonably be
                interpreted as adversely affecting the Provider's performance of
                the Master Agreement or this agreement.
              </li>
            </ul>
            <h4>4. PROVIDER'S EMPLOYEES</h4>
            <ul>
              <li> The Provider will ensure that all of its employees:</li>
              <li>
                are informed of the confidential nature of the Personal Data and
                are bound by confidentiality obligations and use restrictions in
                respect of the Personal Data;
              </li>
              <li>
                have undertaken training on the Data Protection Legislation
                relating to handling Personal Data and how it applies to their
                particular duties; and
              </li>
              <li>
                are aware both of the Provider's duties and their personal
                duties and obligations under the Data Protection Legislation and
                this Addendum.
              </li>
              <li>
                The Provider will take reasonable steps to ensure the
                reliability, integrity and trustworthiness of [and conduct
                background checks consistent with applicable domestic law on]
                all of the Provider's employees with access to the Personal
                Data.
              </li>
            </ul>
            <h4>5. SECURITY</h4>
            <ul>
              <li>
                The Provider must at all times implement appropriate technical
                and organisational measures against unauthorised or unlawful
                processing, access, copying, modification, reproduction, display
                or distribution of the Personal Data, and against accidental or
                unlawful loss, destruction, alteration, disclosure or damage of
                Personal Data including, but not limited to, the security
                measures set out in Appendix 3 . The Provider must document
                those measures in writing and periodically review them at least
                annually to ensure they remain current and complete].
              </li>
              <li>
                The Provider must implement such measures to ensure a level of
                security appropriate to the risk involved, including as
                appropriate:
              </li>
              <li>the pseudonymisation and encryption of personal data;</li>
              <li>
                the ability to ensure the ongoing confidentiality, integrity,
                availability and resilience of processing systems and services;
              </li>
              <li>
                the ability to restore the availability and access to personal
                data in a timely manner in the event of a physical or technical
                incident; and
              </li>
              <li>
                a process for regularly testing, assessing and evaluating the
                effectiveness of the security measures.
              </li>
            </ul>
            <h4>6. PERSONAL DATA BREACH</h4>
            <ul>
              <li>
                The Provider will immediately and in any event without undue
                delay notify the Customer if it becomes aware of:
              </li>
              <li>
                the loss, unintended destruction or damage, corruption, or
                unusability of part or all of the Personal Data. The Provider
                will restore such Personal Data at its own expense as soon as
                possible
              </li>
              <li>
                any accidental, unauthorised or unlawful processing of the
                Personal Data; or
              </li>
              <li> any Personal Data Breach.</li>
              <li>
                Where the Provider becomes aware of (a), (b) and/or (c) above,
                it shall, without undue delay, also provide the Customer with
                the following information:
              </li>
              <li>
                description of the nature of (a), (b) and/or (c), including the
                categories of in-scope Personal Data and approximate number of
                both Data Subjects and the Personal Data records concerned;
              </li>
              <li> the likely consequences; and</li>
              <li>
                a description of the measures taken or proposed to be taken to
                address (a), (b) and/or (c), including measures to mitigate its
                possible adverse effects.
              </li>
              <li>
                Immediately following any accidental, unauthorised or unlawful
                Personal Data processing or Personal Data Breach, the parties
                will co-ordinate with each other to investigate the matter.
                Further, the Provider will reasonably co-operate with the
                Customer at no additional cost to the Customer, in the
                Customer's handling of the matter, including but not limited to:
              </li>
              <li> assisting with any investigation;</li>
              <li>
                providing the Customer with physical access to any facilities
                and operations affected;
              </li>
              <li>
                facilitating interviews with the Provider's employees, former
                employees and others involved in the matter including, but not
                limited to, its officers and directors;
              </li>
              <li>
                making available all relevant records, logs, files, data
                reporting and other materials required to comply with all Data
                Protection Legislation or as otherwise reasonably required by
                the Customer; and
              </li>
              <li>
                taking reasonable and prompt steps to mitigate the effects and
                to minimise any damage resulting from the Personal Data Breach
                or accidental, unauthorised or unlawful Personal Data
                processing.
              </li>
              <li>
                The Provider will not inform any third party of any accidental,
                unauthorised or unlawful processing of all or part of the
                Personal Data and/or a Personal Data Breach without first
                obtaining the Customer's written consent, except when required
                to do so by domestic law.
              </li>
              <li>
                The Provider agrees that the Customer has the sole right to
                determine:
              </li>
              <li>
                whether to provide notice of the accidental, unauthorised or
                unlawful processing and/or the Personal Data Breach to any Data
                Subjects, the Commissioner, other in-scope regulators, law
                enforcement agencies or others, as required by law or regulation
                or in the Customer's discretion, including the contents and
                delivery method of the notice; and
              </li>
              <li>
                whether to offer any type of remedy to affected Data Subjects,
                including the nature and extent of such remedy.
              </li>
              <li>
                The Provider will cover all reasonable expenses associated with
                the performance of the obligations under clause 6.1 to clause
                6.3 unless the matter arose from the Customer's specific written
                instructions, negligence, wilful default or breach of this
                agreement, in which case the Customer will cover all reasonable
                expenses.
              </li>
              <li>
                The Provider will also reimburse the Customer for actual
                reasonable expenses that the Customer incurs when responding to
                an incident of accidental, unauthorised or unlawful processing
                and/or a Personal Data Breach to the extent that the Provider
                caused such, including all costs of notice and any remedy as set
                out in clause 6.5.
              </li>
            </ul>
            <h4>7. CROSS-BORDER TRANSFERS OF PERSONAL DATA</h4>
            <ul>
              <li>
                The Provider (and any subcontractor) must not transfer or
                otherwise process the Personal Data outside the EEA without
                obtaining the Customer's prior written consent.
              </li>
              <li>
                Where such consent is granted, the Provider may only process, or
                permit the processing, of the Personal Data outside the EEA
                under the following conditions:
              </li>
              <li>
                the Provider is processing the Personal Data in a territory
                which is subject to adequacy regulations under the Data
                Protection Legislation that the territory provides adequate
                protection for the privacy rights of individuals. The Provider
                must identify in Appendix 1 the territory that is subject to
                such adequacy regulations; or
              </li>
              <li>
                the Provider participates in a valid cross-border transfer
                mechanism under the Data Protection Legislation, so that the
                Provider (and, where appropriate, the Customer) can ensure that
                appropriate safeguards are in place to ensure an adequate level
                of protection with respect to the privacy rights of individuals
                as required by Article 46 of the UK GDPR [and EU GDPR]. The
                Provider must identify in Appendix 1 the transfer mechanism that
                enables the parties to comply with these cross-border data
                transfer provisions and the Provider must immediately inform the
                Customer of any change to that status; or
              </li>
              <li>
                the transfer otherwise complies with the Data Protection
                Legislation for the reasons set out in Appendix 1 .
              </li>
              <li>
                If any Personal Data transfer between the Customer and the
                Provider requires execution of SCCs in order to comply with the
                Data Protection Legislation (where the Customer is the entity
                exporting Personal Data to the Provider outside the EEA), the
                parties will complete all relevant details in, and execute, the
                SCCs contained in Appendix 2 , and take all other actions
                required to legitimise the transfer.
              </li>
              <li>
                If the Customer consents to appointment by the Provider of a
                subcontractor located outside the EEA in compliance with the
                provisions of clause 8, then the Customer authorises the
                Provider to enter into SCCs contained in Annex B with the
                subcontractor. The Provider will make the executed SCCs
                available to the Customer on request.
              </li>
            </ul>
            <h4>8. SUBCONTRACTORS</h4>
            <ul>
              <li>
                other than those subcontractors as set out in Annex A, the
                Provider may not authorise any other third party or
                subcontractor to process the Personal Data.
              </li>
              <li>
                The Provider may only authorise a third party (subcontractor) to
                process the Personal Data if:
              </li>
              <li>
                is provided with an opportunity to object to the appointment of
                each subcontractor within 7 working days after the Provider
                supplies the Customer with full details in writing regarding
                such subcontractor;
              </li>
              <li>
                the Provider enters into a written contract with the
                subcontractor that contains terms substantially the same as
                those set out in this agreement, in particular, in relation to
                requiring appropriate technical and organisational data security
                measures, and, upon the Customer's written request, provides the
                Customer with copies of the relevant excerpts from such
                contracts;
              </li>
              <li>
                the Provider maintains control over all of the Personal Data it
                entrusts to the subcontractor; and
              </li>
              <li>
                the subcontractor's contract terminates automatically on
                termination of this agreement for any reason
              </li>
              <li>
                Those subcontractors approved as at the commencement of this
                agreement are as set out in Appendix 1 . The Provider must list
                all approved subcontractors in Annex A and include any
                subcontractor's name and location and the contact information
                for the person responsible for privacy and data protection
                compliance.
              </li>
              <li>
                Where the subcontractor fails to fulfil its obligations under
                the written agreement with the Provider which contains terms
                substantially the same as those set out in this agreement, the
                Provider remains fully liable to the Customer for the
                subcontractor's performance of its agreement obligations.
              </li>
              <li>
                The Parties agree that the Provider will be deemed to control
                legally any Personal Data controlled practically by or in the
                possession of its subcontractors.
              </li>
              <li>
                On the Customer's written request, the Provider will audit a
                subcontractor's compliance with its obligations regarding the
                Personal Data and provide the Customer with the audit results.
                Where the Customer concludes reasonably that the subcontractor
                is in material default of its obligations regarding the Personal
                Data, the Customer may in writing instruct the Provider to
                instruct the subcontractor to remedy such deficiencies within 30
                days.
              </li>
            </ul>
            <h4>9. COMPLAINTS, DATA SUBJECT REQUESTS AND THIRD-PARTY RIGHTS</h4>
            <ul>
              <li>
                The Provider must, at no additional cost to the Customer, take
                such technical and organisational measures as may be
                appropriate, and promptly provide such information to the
                Customer as the Customer may reasonably require, to enable the
                Customer to comply with:
              </li>
              <li>
                the rights of Data Subjects under the Data Protection
                Legislation, including subject access rights, the rights to
                rectify, port and erase personal data, object to the processing
                and automated processing of personal data, and restrict the
                processing of personal data; and
              </li>
              <li>
                information or assessment notices served on the Customer by the
                Commissioner [or other relevant regulator] under the Data
                Protection Legislation.
              </li>
              <li>
                The Provider must notify the Customer immediately in writing if
                it receives any complaint, notice or communication that relates
                directly or indirectly to the processing of the Personal Data or
                to either party's compliance with the Data Protection
                Legislation.
              </li>
              <li>
                The Provider must notify the Customer within 2 days if it
                receives a request from a Data Subject for access to their
                Personal Data or to exercise any of their other rights under the
                Data Protection Legislation.
              </li>
              <li>
                The Provider will give the Customer, at no additional cost to
                the Customer, its full co-operation and assistance in responding
                to any complaint, notice, communication or Data Subject request.
              </li>
              <li>
                The Provider must not disclose the Personal Data to any Data
                Subject or to a third party other than in accordance with the
                Customer's written instructions, or as required by domestic law.
              </li>
            </ul>
            <h4>10. TERM AND TERMINATION</h4>
            <ul>
              <li>
                This Addendum will remain in full force and effect so long as:
              </li>
              <li> the Master Agreement remains in effect; or</li>
              <li>
                the Provider retains any of the Personal Data related to the
                Master Agreement in its possession or control (Term).
              </li>
              <li>
                Any provision of this agreement that expressly or by implication
                should come into or continue in force on or after termination of
                the Master Agreement in order to protect the Personal Data will
                remain in full force and effect.
              </li>
              <li>
                The Provider's failure to comply with the terms of this Addendum
                is a material breach of the Master Agreement. In such event, the
                Customer may terminate the Master Agreement effective
                immediately on written notice to the Provider without further
                liability or obligation of the Customer.
              </li>
              <li>
                If a change in any Data Protection Legislation prevents either
                party from fulfilling all or part of its Master Agreement
                obligations, the parties may agree to suspend the processing of
                the Personal Data until that processing complies with the new
                requirements. If the parties are unable to bring the Personal
                Data processing into compliance with the Data Protection
                Legislation within 30 days, either party may terminate the
                Master Agreement on not less than 20 working days written notice
                to the other party.
              </li>
            </ul>
            <h4>11. DATA RETURN AND DESTRUCTION</h4>
            <ul>
              <li>
                At the Customer's request, the Provider will give the Customer,
                or a third party nominated in writing by the Customer, a copy of
                or access to all or part of the Personal Data in its possession
                or control in the format and on the media reasonably specified
                by the Customer
              </li>
              <li>
                On termination of the Master Agreement for any reason or expiry
                of its term, the Provider will securely delete or destroy or, if
                directed in writing by the Customer, return and not retain, all
                or any of the Personal Data related to this agreement in its
                possession or control.
              </li>
              <li>
                If any law, regulation, or government or regulatory body
                requires the Provider to retain any documents or materials or
                Personal Data that the Provider would otherwise be required to
                return or destroy, it will notify the Customer in writing of
                that retention requirement, giving details of the documents,
                materials or Personal Data that it must retain, the legal basis
                for retention, and establishing a specific timeline for deletion
                or destruction once the retention requirement ends
              </li>
              <li>
                The Provider will certify in writing to the Customer that it has
                destroyed the Personal Data within 5 days after it completes the
                deletion or destruction.
              </li>
            </ul>
            <h4>12. RECORDS</h4>
            <ul>
              <li>
                The Provider will keep detailed, accurate and up-to-date written
                records regarding any processing of the Personal Data, including
                but not limited to, the access, control and security of the
                Personal Data, approved subcontractors, the processing purposes,
                categories of processing, any transfers of personal data to a
                third country and related safeguards, and a general description
                of the technical and organisational security measures referred
                to in clause 5.1 (Records).
              </li>
              <li>
                The Provider will ensure that the Records are sufficient to
                enable the Customer to verify the Provider's compliance with its
                obligations under this agreement and the Provider will provide
                the Customer with copies of the Records upon request.
              </li>
              <li>
                The Customer and the Provider must review the information listed
                in the Annexes to this agreement at least once a year to confirm
                its current accuracy and update it when required to reflect
                current practices.
              </li>
            </ul>
            <h4>13. AUDIT</h4>
            <ul>
              <li>
                The Provider will permit the Customer and its third-party
                representatives to audit the Provider's compliance with its
                Agreement obligations, on at least 10 days' notice, during the
                Term. The Provider will give the Customer and its third-party
                representatives all necessary assistance to conduct such audits.
                The assistance may include, but is not limited to:
              </li>
              <li>
                physical access to, remote electronic access to, and copies of
                the Records and any other information held at the Provider's
                premises or on systems storing the Personal Data;
              </li>
              <li>
                access to and meetings with any of the Provider's personnel
                reasonably necessary to provide all explanations and perform the
                audit effectively; and
              </li>
              <li>
                inspection of all Records and the infrastructure, electronic
                data or systems, facilities, equipment or application software
                used to store, process the Personal Data.
              </li>
              <li>
                The notice requirements in clause 13.1 will not apply if the
                Customer reasonably believes that a Personal Data Breach
                occurred or is occurring, or the Provider is in breach of any of
                its obligations under this agreement or any Data Protection
                Legislation.
              </li>
              <li>
                If a Personal Data Breach occurs or is occurring, or the
                Provider becomes aware of a breach of any of its obligations
                under this agreement or any Data Protection Legislation, the
                Provider will:
              </li>
              <li>promptly conduct its own audit to determine the cause;</li>
              <li>
                produce a written report that includes detailed plans to remedy
                any deficiencies identified by the audit;
              </li>
              <li>
                provide the Customer with a copy of the written audit report;
                and
              </li>
              <li>
                remedy any deficiencies identified by the audit within 5 days.
              </li>
              <li>At the Customer's written request, the Provider will:</li>
              <li>
                conduct an information security audit before it first begins
                processing any of the Personal Data and repeat that audit on at
                least an annual basis;
              </li>
              <li>
                produce a written report that includes detailed plans to remedy
                any security deficiencies identified by the audit;
              </li>
              <li>
                provide the Customer with a copy of the written audit report;
                and
              </li>
              <li>
                remedy any deficiencies identified by the audit within 10 days.
              </li>
              <li>
                On the Customer's written request, the Provider will make all of
                the relevant audit reports available to the Customer for review,
                including as applicable: The Provider's latest Payment Card
                Industry (PCI) Compliance Report, WebTrust, Systrust, Statement
                on Standards for Attestation Engagements No. 16 audit reports
                for Reporting on Controls at a Service Organisation, and reports
                relating to its ISO/IEC 27001 certification or equivalent. The
                Customer will treat such audit reports as the Provider's
                confidential information under the Master Agreement.
              </li>
              <li>
                The Provider will promptly address any exceptions noted in the
                audit reports with the development and implementation of a
                corrective action plan by the Provider's management.
              </li>
            </ul>
            <h4>14. WARRANTIES</h4>
            <ul>
              <li> The Provider warrants and represents that:</li>
              <li>
                its employees, subcontractors, agents and any other person or
                persons accessing the Personal Data on its behalf are reliable
                and trustworthy and have received the required training on the
                Data Protection Legislation;
              </li>
              <li>
                it and anyone operating on its behalf will process the Personal
                Data in compliance with the Data Protection Legislation and
                other laws, enactments, regulations, orders, standards and other
                similar instruments;
              </li>
              <li>
                it has no reason to believe that the Data Protection Legislation
                prevents it from providing any of the Master Agreement's
                contracted services; and
              </li>
              <li>
                considering the current technology environment and
                implementation costs, it will take appropriate technical and
                organisational measures to prevent the unauthorised or unlawful
                processing of Personal Data and the accidental loss or
                destruction of, or damage to, Personal Data, and ensure a level
                of security appropriate to:
              </li>
              <li>
                the harm that might result from such unauthorised or unlawful
                processing or accidental loss, destruction or damage;
              </li>
              <li> the nature of the Personal Data protected; and</li>
              <li>
                comply with all applicable Data Protection Legislation and its
                information and security policies, including the security
                measures required in clause 5.1.
              </li>
              <li>
                The Customer warrants and represents that the Provider's
                expected use of the Personal Data for the Business Purposes and
                as specifically instructed by the Customer will comply with the
                Data Protection Legislation.
              </li>
            </ul>
            <h4>15. INDEMNIFICATION</h4>
            <ul>
              <li>
                The Provider agrees to indemnify, keep indemnified and defend at
                its own expense the Customer against all costs, claims, damages
                or expenses incurred by the Customer or for which the Customer
                may become liable due to any failure by the Provider or its
                employees, subcontractors or agents to comply with any of its
                obligations under this agreement or the Data Protection
                Legislation.
              </li>
              <li>
                Any limitation of liability set forth in the Master Agreement
                will not apply to this agreement's indemnity or reimbursement
                obligations.
              </li>
            </ul>
            <h4>16. NOTICE</h4>
            <ul>
              <li>
                Any notice given to a party under or in connection with this
                agreement must be in writing and delivered to:
              </li>
              <li> For the Customer: [CUSTOMER DATA PRIVACY CONTACT]</li>
              <li> For the Provider: [PROVIDER DATA PRIVACY CONTACT]</li>
              <li>
                Clause 16.1 does not apply to the service of any proceedings or
                other documents in any legal action or, where applicable, any
                arbitration or other method of dispute resolution.
              </li>
              <li>
                [A notice given under this agreement is not valid if sent by
                email].
              </li>
            </ul>
            <h4>
              THIS AGREEMENT HAS BEEN ENTERED INTO ON THE DATE STATED AT THE
              BEGINNING OF IT.
            </h4>

            <div className="row">
              <div className="col-lg-6">
                <p>Signed by [NAME OF DIRECTOR]</p>
                <p>for and on behalf of Toothaid Limited] (Customer)</p>
                <p>Signed by [NAME OF DIRECTOR]</p>
                <p>for and on behalf of [NAME OF Provider]</p>
              </div>
              <div className="col-lg-6">
                <p></p>
                <p>Director</p>
                <p></p>
                <p>Director</p>
              </div>
            </div>
          </div>
        </div>
      </LayoutSymtom>
    </>
  );
}
