import admin_avatar_header from "../../../assets/images/avatar.svg";
const TitlewSearch = () => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div>
            <span className="small_text">ToothAid</span>
            <h4 className="mb-sm-0 font-size-28">Dashboard </h4>
          </div>
          <div className="page-title-right">
            {/* <!-- App Search-->  */}
            {/* <form className="app-search d-none d-lg-block ">
              <div className="d-flex justify-content-between align-items-center">
                <div className="position-relative w-100">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                  <span className="bx bx-search"></span>
                </div>
                <img src={admin_avatar_header} className="avata_serch" alt="" />
              </div>
            </form> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitlewSearch;
