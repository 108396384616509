import React, { useRef } from "react";
import logoMain from "../../../assets/images/logo-1.svg";
import expand_right from "../../../assets/images/icons/expand_right.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TopBarBlack from "../topbarUser/TopBarBlack";
import { LayoutBlackTop } from "../../Layout/LayoutBlackTop";
import { useEffect } from "react";
import { useState } from "react";
import OtherCommonIssues from "../common_issues/OtherCommonIssues";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import TrustPilot from "../homepage/TrustPilot";
 
export default function ToothDecay() {
  const ref = useRef()
  const [toothDecay]=useState('TOOTH_DECAY')
  const navigate = useNavigate();
  const handleDentistClick = () => {
    if (sessionStorage.getItem("token")) {
      navigate("/symptom-checker-start");
    } else {
      navigate("/userlogin");
    }
  };


  const handleDownloadPdf = async () => {
    const element = ref.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'px', 'a4');
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight()

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('Toothaid.pdf');

  };
  return (
    <div>
    <LayoutBlackTop>
      <div id="wrapper">
        {/* <!-- header start --> */}
        
        {/* <!-- header end -->
       <!-- banner  start --> */}
       <div ref={ref}>
        <section className="tooth-decay">
          <div className="container-fluid container_inner_width">
            <div className="banner-content">
              <h1>Tooth Cavity</h1>
              <p className="banner-p-text">A hole in in the tooth that has formed as a result of tooth decay (caries) overtime.</p>       
            </div>
          </div>
        </section>
        {/* <!-- banner  end --> 
      <!-- common banner bottom part start --> */}
        <section className="trust_wrapper position-relative">
          <div className="banner_btm">
            <div className="btm_logo d-none">
              {/* <img src={logoMain} alt="bottom-logo" className="img-fluid" /> */}
            </div>
            <TrustPilot />

            <div className="btm_ques">
              <p onClick={()=>handleDentistClick()} className="book_dentist_link">
                  Having issues? Book in with a dentist{" "}
                <img src={expand_right} alt="right-arrow" />
              </p>
            </div>
          </div>
        </section>
        {/* <!-- common banner bottom part end -->

        <!--risk factors start--> */}
        <section className="section-divide risk_factors">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="">
                  <div className="p-3 risk_factors_list">
                    <h2 className="mb-4">How does caries develop?</h2>
                    <p className="large_parah mb-5">There are 4 factors that lead to the development of caries(decay):</p>
                    <ul className="common-pointers">
                      <li>Bacteria</li>
                      <li>Sugar</li>
                      <li>Tooth</li>
                      <li>Time</li>
                    </ul>             
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              <ul
                                    className="d-flex p-0
                                                img-section-row"
                                  >
                                    <li className="list-style-none">
                                      <img
                                        src={require("../../../assets/images/prevention-education/tooth_prblm_8.jpg")}
                                        alt=""
                                      />
                                    </li>
                                    <li
                                      className="list-style-none
                                                    ml-30"
                                    >
                                      <img
                                        src={require("../../../assets/images/prevention-education/cavity_new.jpg")}
                                        alt=""
                                      />
                                      <p
                                        className="text-center
                                                        mb-0"
                                      >
                                        A cavity in its later stages
                                      </p>
                                    </li>
                                  </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="section-divide ">
            <div className="container">            
            <div className="types_causes">
            <div className="mt-4 mb-4 main_section_title">
              <h2 className="text-center ">Prevention &amp; Treatment</h2>
            </div>
            <div className="row mb-5">
              <div className="col-md-6">
                <div className="causes_box_shadow treatment_detail h-400 cavity_box">
                  <div>
                    <h4>Prevention</h4>
                    <ul className="causes-detail">
                      <li>
                      Strengthening and protecting the tooth:
                        <ul className="list-style-none inner_points">
                            <li>-	Use a fluoridated tooth paste and see a dentist for fluoride application. </li>
                            <li>-	A dentist may also seal the biting surface of the tooth to prevent food and bacteria from getting in. </li>
                        </ul>
                      </li>
                      <li>Low sugar intake and regular, effective brushing</li>
                      <li>Seeing a dentist/hygienist every 3 to 6 months for a clean. </li>                   
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="causes_box_shadow treatment_detail h-400 cavity_box">
                  <h4>Treatment</h4>
                  <ul className="causes-detail">
                    <li>
                    If the cavity becomes large and the decay extends into the inner surface of the tooth (the dentine) the tooth will need a filling. This is when the tooth decay is cleaned out by dentist and sealed from the oral environment with the use a filling material. It is important to still incorporate preventative measures so that caries doesn’t return to treated teeth or develop in new teeth. 
                    </li>
                    <li>It is important to try treat a cavity early, before the nerve of the tooth becomes infected or tooth much tooth structure is lost as other more invasive treatment may be required. </li>                   
                    <li>
                    Note: if a cavity develops in the root of the tooth a dentist may be unable to restore it and save the tooth.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>  
            </div>
          </section>
        <section className="section-divide p-120-60">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 justify-content-center align-items-center d-flex short-logo">
                <img
                  src={require("../../../assets/images/logo-short.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-6">
                <div className="about-us-title d-flex justify-content-center align-items-center mb-3">
                  <h2>Dr. Adam Patel Says</h2>
                </div>
                <p>
                  Almost all problems can be avoided by attending for your regular 6 monthly checkups and ensuring good quality oral hygiene methods at home!
                </p>
                <div className="d-flex justify-content-center mt-4">
                  <a onClick={()=>handleDownloadPdf()} className="white-btn download_btn">
                    Download Our Guide
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
        {/* <!-- Guide Section end -->
        <!--other common issues image start--> */}
        <OtherCommonIssues toothDecay={toothDecay}/>
        {/* <section className="section-divide p-120">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main_section_title">
                  <h2 className="text-center mb-5">Other Common Issues</h2>
                </div>
                <div className="main_section_content ">
                  <div className="row">
               

                    <div className="col-md-3">
                      <Link
                        className="common_issues_inner position-relative"
                        to="/tooth-decay"
                        onClick={()=>{window.scrollTo(0, 0)}}
                      >
                        <img
                          src={require("../../../assets/images/problems/imp-issues.png")}
                          alt="bbc-logo"
                          className="img-fluid"
                        />
                        <h3 className="position-absolute absolute-txt">
                          Tooth <br />
                          Decay
                        </h3>
                      </Link>
                    </div>
                    <div className="col-md-3">
                      <Link
                        className="common_issues_inner position-relative"
                        to="/jaw-problems"
                      >
                        <img
                          src={require("../../../assets/images/problems/imp-issues.png")}
                          alt="daily-mail"
                          className="img-fluid"
                        />
                        <h3 className="position-absolute absolute-txt">
                          Jaw <br />
                          Problems
                        </h3>
                      </Link>
                    </div>
                    <div className="col-md-3">
                      <Link
                        className="common_issues_inner position-relative"
                        to="/missing-teeth"
                      >
                        <img
                          src={require("../../../assets/images/problems/imp-issues.png")}
                          alt="guardian-logo"
                          className="img-fluid"
                        />
                        <h3 className="position-absolute absolute-txt">
                          Missing <br />
                          Teeth
                        </h3>
                      </Link>
                    </div>
                    <div className="col-md-3">
                      <Link
                        className="common_issues_inner position-relative"
                        to="/bad-breathe"
                      >
                        <img
                          src={require("../../../assets/images/problems/imp-issues.png")}
                          alt="cosmopolitan"
                          className="img-fluid"
                        />
                        <h3 className="position-absolute absolute-txt">
                          Bad <br /> Breath
                        </h3>
                      </Link>
                    </div>
                 
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <!--other common issues image end-->
         <!-- Call-to-action start --> */}
      
        {/* <!-- Call-to-action end -->

        <!-- Footer Start --> */}
        
        {/* <!-- Footer End --> */}
      </div>
      </LayoutBlackTop>
    </div>
  );
}
