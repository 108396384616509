
function getUserIdFromToken() {
  const token = window.sessionStorage.getItem("token");
  if (!token) return null;

  try {

    const payload = JSON.parse(atob(token.split('.')[1])); 
    return payload.id || null; 
  } catch (error) {
    console.error("Invalid token format:", error);
    return null;
  }
}

 export const currentUserId = getUserIdFromToken();