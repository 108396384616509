import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const RTE = ({ htmlText }) => {
  const [description, setDescription] = useState({
    htmlValue: "<p></p>\n",
    editorState: EditorState.createEmpty(),
  });

  const onEditorStateChange = (editorValue) => {
    const editorStateInHtml = draftToHtml(
      convertToRaw(editorValue.getCurrentContent())
    );

    setDescription({
      htmlValue: editorStateInHtml,
      editorState: editorValue,
    });

    htmlText(JSON.stringify(convertToRaw(editorValue.getCurrentContent())));
  };
  return (
    <div>
      <Editor
        toolbarHidden={false}
        editorState={description.editorState}
        onEditorStateChange={onEditorStateChange}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        placeholder="Type Here...."
      />
    </div>
  );
};

// toolbar={{
//     options: [
//       "inline",
//       "list"
//     ],
//     inline: {
//       options: [
//         "bold",
//         "italic",
//         "underline"
//       ]
//     },
//     list: {
//       options: ["unordered", "ordered"]
//     }
//   }}

export default RTE;
