import React, { useEffect } from "react";
import Layout from "../layout/Layout";
import admin_avatar_header from "../../../assets/images/avatar.svg";
import { Link } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getAllBecomeDoctor } from "../../../Redux/Actions/admin/adminPanel";
import moment from "moment";



const BecomeDentistAdmin = () => {
  const dispatch = useDispatch();
  const becomeDoctors = useSelector((state) => state.adminPanel.becomeDoctors);

  useEffect(() => {
    dispatch(getAllBecomeDoctor());
  }, []);

  return (
    <>

      <Layout>
        <div id="layout-wrapper" className="bg-dash admin-table">
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <div>
                        <span className="small_text">Dentists</span>
                        <h4 className="mb-sm-0 font-size-28">
                          Become A Dentitst
                          <span className="header-title-text">
                            {becomeDoctors?.length}
                          </span>
                        </h4>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-12">
                    <div className="members_tbl">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                            <h4 className="title_text">Become A Dentitst</h4>
                          </div>
                          <div className="table-responsive table-defaut-design dealer-table-c">
                            <table
                              id="datatable"
                              className="table  vehicles_table  w-100"
                            >

                              <thead>
                                <tr>
                                  <th>Created</th>
                                  <th>Full Name</th>
                                  <th>DOB</th>
                                  <th>Email</th>
                                  <th>Phone</th>
                                  <th>First Line of Address</th>
                                  <th>Second Line of Address</th>
                                  <th>Town/City</th>
                                  <th>Post Code</th>
                                  <th>University Attended</th>
                                  <th>Highest Qualification Level</th>
                                  <th>Year of Graduation</th>
                                  <th>GDC Number</th>
                                  <th>Indemnity Provider</th>
                                  <th>Indemnity Number</th>
                                </tr>
                              </thead>
                              <tbody className="td_color trans_table_fix_height">
                                {
                                  becomeDoctors?.map((item, i) => {
                                    return (

                                      <tr>
                                        <td>{moment(item?.createdAt).format(
                                          "DD/MM/YYYY"
                                        )}</td>
                                        <td>{item?.fullname}</td>
                                        <td>{item?.dob}</td>
                                        <td>{item?.email}</td>
                                        <td>{item?.phone}</td>
                                        <td>{item?.addressone}</td>
                                        <td>{item?.addresstwo}</td>
                                        <td>{item?.town_city}</td>
                                        <td>{item?.postcode}</td>
                                        <td>{item?.university_attend}</td>
                                        <td>{item?.qualification}</td>
                                        <td>{item?.year_of_graduation}</td>
                                        <td>{item?.gdc_number}</td>
                                        <td>{item?.indemnity_provider}</td>
                                        <td>{item?.indemnity_number}</td>
                                      </tr>

                                    )
                                  })
                                }

                              </tbody>
                            </table>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-12">
                              <div className="select-table-bottom-r d-flex justify-content-end">
                                <label>
                                  Results per page
                                  <select
                                    onChange={(event) =>
                                      setPage(event.target.value)
                                    }
                                    className="select-page-count-bottom"
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </Layout>
    </>

  )
}

export default BecomeDentistAdmin