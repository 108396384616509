export const medicationArr =  [
    {
        Medication: "Aciclovir Cream 5%",
        Supply: "2g ",
    },
    {
        Medication: "Aciclovir Tablets, 200 mg",
        Supply: "25 Tablets",
    },
    {
        Medication: "Amoxicillin Capsules, 250 mg",
        Supply: "15 Capsules",
    },
    {
        Medication: "Amoxicillin Capsules, 500 mg",
        Supply: "15 Capsules",
    },
    {
        Medication: "Amoxicillin Oral Suspension 125 mg/5 ml",
        Supply: "Bottle",
    },
    {
        Medication: "Amoxicillin Oral Suspension 250 mg/5 ml",
        Supply: "Bottle",
    },
    {
        Medication: "Benzydamine Mouthwash, 0.15%",
        Supply: "300 ml",
    },
    {
        Medication: "Benzydamine Oromucosal Spray, 0.15%",
        Supply: "30 ml",
    },
    {
        Medication: "Betamethasone Soluble Tablets, 500 microgram",
        Supply: "100 tablets",
    },
    {
        Medication: "Chlorhexidine Mouthwash, 0.2%",
        Supply: "300 ml",
    },
    {
        Medication: "Clarithromycin Oral Suspension 125 mg/5 ml",
        Supply: "Bottle",
    },
    {
        Medication: "Clarithromycin Oral Suspension 250 mg/5 ml",
        Supply: "Bottle",
    },
    {
        Medication: "Clarithromycin Tablets 250 mg",
        Supply: "14 Tablets",
    },
    {
        // Medication: "Clenil Modulite®€¡, 50 micrograms",
        Medication: "Clenil Modulite®, 50 micrograms",
        Supply: "One 200-dose unit",
    },
    {
        Medication: "Clindamycin Capsules, 150 mg",
        Supply: "20 Capsules",
    },
    {
        Medication: "Co-amoxiclav 25/125 Tablets",
        Supply: "15 Tablets",
    },
    {
        Medication: "Co-amoxiclav 250/125 Tablets",
        Supply: "15 Tablets",
    },
    {
        Medication: "Doxycycline Capsules, 100 mg",
        Supply: "8 capsules",
    },
    {
        Medication: "Doxycycline Dispersible Tablets, 100 mg",
        Supply: "48 Tablets",
    },
    {
        Medication: "Ephedrine Nasal Drops, 0.5%",
        Supply: "10 ml",
    },
    {
        Medication: "Fluconazole Capsules, 50 mg",
        Supply: "7 capsules",
    },
    {
        Medication: "Hydrocortisone Oromucosal Tablets, 2.5 mg",
        Supply: "20 tablets",
    },
    {
        Medication: "Hydrogen Peroxide Mouthwash, 6%",
        Supply: "300 ml",
    },
    {
        Medication: "Lidocaine Ointment, 5%",
        Supply: "15g",
    },
    {
        Medication: "Lidocaine Spray, 10%",
        Supply: "50 ml",
    },
    {
        Medication: "Metronidazole Oral Suspension, 200 mg/ 5 ml",
        Supply: "Bottle",
    },
    {
        Medication: "Metronidazole Tablets, 200 mg",
        Supply: "15 Tablets",
    },
    {
        Medication: "Metronidazole Tablets, 400 mg",
        Supply: "15 Tablets",
    },
    {
        Medication: "Miconazole Cream, 2%",
        Supply: "20 g tube",
    },
    {
        Medication: "Miconazole Oromucosal Gel*, 20 mg/g",
        Supply: "80 g tube",
    },
    {
        Medication: "Sodium Chloride Mouthwash, Compound",
        Supply: "300 ml",
    },
    {
        Medication: "Sodium Fusidate Ointment, 2%",
        Supply: "15 g tube",
    },
];