import { useState, useEffect, useRef } from "react";
import Layout from "../layout/Layout";
import ChatBox from "./ChatBox";
import io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getConsInfo } from "../../../Redux/Actions/admin/adminPanel";
import MomentFunc from "../../../utils/MomentDateTime";
import ConsNotes from "./ConsNotes";
import VideoCall from "./VideoCall";
import { startstopLoading } from "../../../Redux/Reducers/globalSlice";
import Cookies from "universal-cookie";

const ConsultationWEBRTC = () => {
  const cookies = new Cookies();
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const [socket, setSocket] = useState();
  const socketRef = useRef();
  const { id } = useParams();
  const [me, setMe] = useState("");
  const dispatch = useDispatch();
  const consInfo = useSelector((state) => state.adminPanel.consInfo);
  console.log(consInfo,"consInfo");
  const [onlineUsers, setOnlineUsers] = useState();

  useEffect(() => {
    dispatch(startstopLoading(true));
    socketRef.current = io.connect(BASE_URL);
    socketRef.current.emit("video-user-add", "doctor");
    socketRef.current.on("me", (myId) => setMe(myId));
    setSocket(socketRef.current);
    dispatch(getConsInfo(id)).then((res) => {
      if (res?.payload?.success == true || res?.payload?.success == false) {
        dispatch(startstopLoading(false));
      }
    });
    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  useEffect(() => {
    socketRef.current.on("video-users", (users) => {
      setOnlineUsers(users);
    });
  }, [socketRef.current]);

  const handleUserClick = () => {
    const admintoken = sessionStorage.getItem("token");
    cookies.set("admintoken", admintoken, {
      path: "/",
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
    });
    const liveURL = process.env.REACT_APP_LIVE_URL;
    // cookies.set("consVar", "false");
    const user_id = consInfo.cons.user_id._id;
    // console.log(liveURL)
    const url = `https://admin.toothaid.co/cons/memberdetails/${user_id}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  console.log(me,
    consInfo,
    onlineUsers,"onlineUsers");

  return (
    <Layout>
      <div id="layout-wrapper" className="bg-dash ">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                {consInfo?.cons && (
                  <div className="col-12">
                    <div className=" cons-top-bar page-title-box d-sm-flex align-items-center justify-content-between">
                      <div>
                        <span className="small_text">Consultation</span>
                        <h4 className="mb-sm-0 font-size-28">
                          Consultation with{" "}
                          <span
                            onClick={handleUserClick}
                            className="name_color cursor-pointer"
                          >
                            {consInfo?.cons?.user_id?.firstName +
                              " " +
                              consInfo?.cons?.user_id?.lastName}
                          </span>
                        </h4>
                        <div className="mt-2 sub_head p-0">
                          <h4 className="sub-heading-text-header ms-2 ">
                            {MomentFunc.Date(consInfo?.cons?.date)}
                            <span className="">
                              {MomentFunc.Time(consInfo?.cons?.date)}
                            </span>
                          </h4>
                        </div>
                      </div>
                      <div className="page-title-right">
                        {/* <!-- App Search--> */}
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-lg-12">
                  <div className="desc_area pb-0">
                    <div className="row">
                      <div className="col-lg-7">
                        {socket && me && consInfo && (
                          <VideoCall
                            me={me}
                            consInfo={consInfo.cons}
                            socket={socket}
                            onlineUsers={onlineUsers}
                          />
                        )}
                        {consInfo && socket ? (
                          <ChatBox socket={socket} consInfo={consInfo} />
                        ) : null}
                      </div>
                      {consInfo && socket ? (
                        <ConsNotes socket={socket} consInfo={consInfo} />
                      ) : null}
                      {/* <Questionnare /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ConsultationWEBRTC;
