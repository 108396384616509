import React, { useEffect } from "react";
import Layout from "../layout/Layout";
import admin_avatar_header from "../../../assets/images/avatar.svg";
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getDentist, getNewRequests, assignNewDoctor } from "../../../Redux/Actions/admin/adminPanel";
import moment from "moment";



const ConsultationsAll = () => {

  const dispatch = useDispatch();

  const { new_requests, dentists, assign_new_doctor } = useSelector(state => ({
    new_requests: state.adminPanel.new_requests,
    dentists: state.adminPanel.dentists,
    assign_new_doctor: state.adminPanel.assign_new_doctor
  }));

  const [filter, setFilter] = useState({
    search: undefined,
    date: undefined,
    assigned_to:undefined
  })

  useEffect(() => {
    dispatch(getNewRequests({filter:filter}));
    dispatch(getDentist({ filter: filter }))
  }, [])

  console.log(assign_new_doctor, new_requests, "new_requests")

  const handleFilter = (e) => {
    let { value, name } = e.target;
    setFilter({ ...filter, [name]: value })
    dispatch(getNewRequests({filter : { ...filter, [name]: value }}));
  }

  const handleAssignDentist = (e, id) => {
    console.log(e.target.value, "assignNewDoctor");
    dispatch(assignNewDoctor({ consId: id, dentistId: e.target.value }))
  }

  return (
    <>
      <Layout>
        <div id="layout-wrapper" className="bg-dash admin-table">
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <div>
                        <span className="small_text">Consultations</span>
                        <h4 className="mb-sm-0 font-size-28">
                          Consultations
                          <span className="header-title-text">
                            {new_requests?.length}
                          </span>
                        </h4>
                      </div>
                      {/* <div className="page-title-right">
                        <form className="app-search d-none d-lg-block ">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="position-relative w-100">
                              <input type="text" className="form-control" placeholder="Search" />
                              <span className="bx bx-search"></span>
                            </div>
                            <img
                              src={admin_avatar_header}
                              className="avata_serch"
                              alt=""
                            />
                          </div>
                        </form>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                    <input 
                    name="search"
                    type="text" 
                    class="form-control filter-input-element" 
                    id="" 
                    placeholder="Search Here" 
                    onChange={(e) => handleFilter(e)}
                    />
                  </div>
                  <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4">
                    <div class="form-group">
                      <input 
                      name="date"
                      type="date" 
                      class="form-control filter-input-element"
                       id="" 
                       placeholder="Search Here"
                       onChange={(e) => handleFilter(e)}
                       />
                    </div>
                  </div>
                  {/* <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4">
                    <div class="form-group">
                      <select class="form-control filter-select-element">
                        <option>Status</option>
                        <option>2</option>
                      </select>
                    </div>
                  </div> */}
                  { sessionStorage.getItem("role") != "dentist" &&
                  <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4">
                    <div class="form-group">

                      <select
                      name="assigned_to"
                        // value={item?.assignedDoctor}
                        onChange={(e) => handleFilter(e)} 
                        class="form-control filter-select-element"
                      //disabled={true}
                      >
                        <option value={""}>Assigned To</option>
                        {
                          dentists?.map((item) => {
                            return (
                              <>
                                <option value={item?._id}>{item?.firstName + " " + item?.lastName}</option>
                              </>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                  }
                
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="members_tbl">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                            <h4 className="title_text">All Consultations</h4>
                          </div>
                          <div className="table-responsive table-defaut-design dealer-table-c">
                            <table
                              id="datatable"
                              className="table  vehicles_table  w-100"
                            >
                              <thead>
                                <tr>
                                  <th>Created</th>
                                  <th>Name</th>
                                  <th>Past Consultations</th>
                                  <th>Paid to Date</th>
                                  <th>Requested Date</th>
                                  <th>Status</th>
                                  {
                                       sessionStorage.getItem("role") != "dentist" &&  
                                       <th>Assigned to</th>
                                  }
                                 

                                </tr>
                              </thead>
                              <tbody className="td_color trans_table_fix_height">

                                {
                                  new_requests?.map((item) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>{moment(item?.createdAt).format(
                                            "DD/MM/YYYY hh:mm"
                                          )}</td>
                                          <td>{item?.user_id?.firstName + " " + item?.user_id?.lastName}</td>
                                          <td>-</td>
                                          <td>£{item?.price}</td>
                                          <td>{moment(item?.date).format(
                                            "DD/MM/YYYY hh:mm"
                                          )}</td>
                                          <td>{item?.assignedDoctor ? "Accepted" : "Pending"}</td>
                                          {
                                              sessionStorage.getItem("role") != "dentist" && <td>
                                              <div className="drop-select">
                                                <select
                                                  value={item?.assignedDoctor}
                                                  onChange={(e) => handleAssignDentist(e, item?._id)}
                                                  className={item?.assignedDoctor ? "form-control td-select-element disable_consult" : "form-control td-select-element"}
                                                disabled={item?.assignedDoctor ? true : false}
                                                >
                                                  <option value={""}>Not Assigned</option>
                                                  {
                                                    dentists?.map((item) => {
                                                      return (
                                                        <>
                                                          <option value={item?._id}>{item?.firstName + " " + item?.lastName}</option>
                                                        </>
                                                      )
                                                    })
                                                  }
                                                </select>
                                              </div>
                                            </td>
                                          }
                                   
                                        </tr>
                                      </>
                                    )
                                  })
                                }

                              </tbody>
                            </table>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-12">
                              <div className="select-table-bottom-r d-flex justify-content-end">
                                <label>
                                  Results per page
                                  <select
                                    onChange={(event) =>
                                      setPage(event.target.value)
                                    }
                                    className="select-page-count-bottom"
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </Layout>
    </>

  )
}

export default ConsultationsAll