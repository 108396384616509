import { useEffect } from "react";
import Layout from "../layout/Layout";
import FirstRow from "./FirstRow";
import SecondRow from "./SecondRow";
import TitlewSearch from "./TitlewSearch";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../../Redux/Actions/admin/adminPanel";
import { startstopLoading } from "../../../Redux/Reducers/globalSlice";

export default function Dashboard() {
  const dispatch=useDispatch()
  const usersDashboard=useSelector((state)=>state?.adminPanel?.dashboardData)
  useEffect(()=>{
    dispatch(startstopLoading(true))
    dispatch(getDashboardData()).then((res)=>{
      if(res?.payload?.success==true ||res?.payload?.success==false){
        dispatch(startstopLoading(false))
      }
    })
  },[])
  return (
    <Layout>
      <div id="layout-wrapper" className="bg-dash admin-table">
        {/* <!-- header start dashboard*/}

        {/* <!-- header end dashboard*/}

        {/* <!-- ========== Left Sidebar Start ========== --> */}

        {/* <!-- Left Sidebar End --> */}

        {/* <!-- ============================================================== -->
<!-- Start right Content here -->
<!-- ============================================================== --> */}
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* <!-- start page title --> */}
              <TitlewSearch />
              {/* <!-- end page title -->

            <!-- dashboard first row start --> */}
              <FirstRow usersDashboard={usersDashboard}/>
              {/* <!-- dashboard first row end --> */}
              <SecondRow usersDashboard={usersDashboard}/>
            </div>
            {/* <!-- container fluid --> */}
          </div>
          {/* <!-- End Page-content --> */}
        </div>
        {/* <!-- end main content--> */}
      </div>
    </Layout>
  );
}
